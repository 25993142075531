:root {
  --color-primary: #ACCEDE;
  --color-secondary: #DEEDED;
  --color-third: #DECADE;
  --color-fourth: #CABBED;
  --color-fifth: #f5ebeb;
}


.color-primary {
  background-color: var(--color-primary);
}

.color-secondary {
  background-color: var(--color-secondary);
}

.color-third {
  background-color: var(--color-third);
}

.color-fourth {
  background-color: var(--color-fourth);
}

.color-fifth {
  background-color: var(--color-fifth);
}

.text-color-fourth {
  color: var(--color-primary);
}